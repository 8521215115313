import styled from "styled-components";
import { colors, mediaQueryMax } from "../../../assets/styles/vars";
import { CustomLink } from "../../../assets/styles/globalCommon";

export const ColumnsListWrapper = styled.div``;

export const ColumnsTabNav = styled.div`
	border: 1px solid ${colors.black00};
	border-radius: 5px;
	padding: 5px;
	gap: 5px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 25px;
	button {
		cursor: pointer;
		display: block;
		flex: 1;
		border-radius: 5px;
		padding: 6px;
		text-align: center;
		color: ${colors.black00};
		transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
		span {
			font-size: 16px;
			line-height: 1;
			font-weight: 500;
		}
		&:hover,
		&.active {
			background-color: ${colors.black00};
			color: ${colors.white};
		}
	}
`;

export const ColumnsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 25px;
`;

export const Column = styled.div`
	flex: 1;
	border-radius: 5px;
	padding: 25px;
	box-sizing: border-box;
	overflow: hidden;
	background-color: ${(props) =>
		props.layout === "dark" ? colors.black00 : colors.white};
	color: ${(props) =>
		props.layout === "dark" ? colors.light20 : colors.gray80};
	@media ${mediaQueryMax.lg} {
		flex: 0 0 100%;
		max-width: 100%;
		display: ${(props) => (props.current ? "block" : "none")};
	}
`;

export const ColHeader = styled.div`
	margin-bottom: 20px;
	.headerTop {
		text-align: right;
		margin-bottom: 10px;
		h6 {
			color: ${(props) =>
				props.layout === "dark" ? colors.white : colors.black00} !important;
			font-weight: 500;
			span {
				text-decoration: line-through;
				font-weight: 400;
				font-size: 16px;
			}
		}
	}
	.headerTitle {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 10px;
		gap: 0 10px;
	}
	.headerText {
		text-align: right;
		margin-bottom: 20px;
		p {
			font-size: 15px;
			color: ${colors.gray};
			@media ${mediaQueryMax.xs2} {
				font-size: 13px;
			}
		}
	}
	h3 {
		flex: 1;
		margin: 0;
		color: ${(props) =>
			props.layout === "dark" ? colors.white : colors.black00} !important;
	}
	.priceWrapper {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: end;
		gap: 10px;
		@media ${mediaQueryMax.xl} {
			align-items: center;
		}
		@media ${mediaQueryMax.lg} {
			align-items: flex-end;
		}
		@media ${mediaQueryMax.xs2} {
		}

		h6.price {
			line-height: 1;
			font-weight: 500;
			text-align: center;
			background-color: ${(props) =>
				props.layout === "dark" ? colors.white : colors.black00};
			color: ${(props) =>
				props.layout === "dark" ? colors.black00 : colors.white} !important;
			padding: 10px;
			border-radius: 5px;
			margin: 0;
		}
	}
	${CustomLink} {
		width: 100%;
		text-align: center;
		p {
			font-weight: 500;
		}
	}
`;

export const ColContent = styled.div`
	margin-bottom: 60px;
	h5 {
		margin: 0 0 20px;
		color: ${(props) =>
			props.layout === "dark" ? colors.white : colors.black00} !important;
	}
	.listWrapper {
		.listItem {
			margin-bottom: 5px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 10px;
			border-bottom: 1px solid
				${(props) =>
					props.layout === "dark" ? colors.gray70 : colors.gray40};
			padding: 6px 0;
			p {
				flex: 1;
				margin: 0;
				font-size: 15px;
				font-weight: 300;
			}
			div {
				width: 38px;
				display: flex;
				align-items: center;
				justify-content: center;
				h6 {
					text-align: center;
					font-weight: 500;
					margin: 0;
					color: ${(props) =>
						props.layout === "dark"
							? colors.white
							: colors.black00} !important;
				}
				span.icon {
					width: 18px;
					svg {
						path {
							fill: ${colors.main};
						}
					}
				}
			}
			&.halfAccess {
				span.icon {
					svg {
						path {
							fill: ${colors.gray40};
						}
					}
				}
			}
			&.disabled {
				p {
					color: ${colors.gray40};
				}
				span.icon {
					svg {
						path {
							fill: ${(props) =>
								props.layout === "dark"
									? colors.white
									: colors.black00};
						}
					}
				}
			}
		}
	}
`;

export const ColFooter = styled.div`
	a,
	button {
		margin-bottom: 20px;
	}
	p {
		color: ${colors.gray};
		margin: 0;
		font-size: 16px;
		a {
			color: ${colors.gray} !important;
		}
	}
`;
