import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { connect } from "react-redux";
import { appQueuedRedirect, appModal } from "../redux/app/actions";
import {
	PageContent,
	PageContentWrapper,
	PageHero,
	PageHeroHeader,
	PageHeroTitle,
	PageTwoColWrapper,
} from "../assets/styles/pageCommon";
import {
	CustomButton,
	CustomLink,
	CustomLinkExternal,
} from "../assets/styles/globalCommon";
import PageHeader from "../components/Common/PageHeader";
import { colors } from "../assets/styles/vars";
import ModulesList from "../components/Common/ModulesList";
import {
	faqsData,
	pricesColumns,
	subscriptionModulesItems,
} from "../data/global-data";
import AccordionCustom from "../components/Common/AccordionCustom";
import ColumnsList from "../components/Common/ColumnList";
import { SliderButton } from "@typeform/embed-react";
import TextButtonSkeleton from "../components/Common/PageHeader/TextButtonSkeleton";

const SuscripcionPage = ({
	authResolved,
	loggedIn,
	usuario,
	coupon,
	appQueuedRedirect,
	appModal,
}) => {
	const openLogin = () => {
		appQueuedRedirect({
			to: "https://buy.stripe.com/aEU8zAdnW9qJ0rC6ou?prefilled_email=<userEmail>&client_reference_id=<userId>&prefilled_promo_code=<coupon>",
			external: true,
			after: "login",
		});
		appModal("popuplogin", { campaign: "signup" });
	};

	return (
		<Layout>
			<Seo title="Mi Suscripción" />
			<section>
				<PageHero bgImg="/subscriptions/subscriptions-bg.jpg">
					<PageHeroHeader layout="light">
						<div>
							<CustomLink
								className="secondary ghost withUnderline"
								to="/"
							>
								<p>Volver a la home</p>
							</CustomLink>
						</div>
					</PageHeroHeader>
					<PageHeroTitle layout="light">
						<div className="content">
							<div className="title">
								<h1>Únete a nuestra comunidad</h1>
							</div>
							<div className="description">
								<h4>
									Regístrate gratis o sácale todo el provecho a la
									nieve con nuestro plan premium
								</h4>
							</div>
						</div>
					</PageHeroTitle>
				</PageHero>

				<PageHeader
					sticky={true}
					title="¡Conviérte en Smart!"
					text="Disfruta una experiencia digital en la nieve."
				>
					<div className="pageHeaderContent">
						{authResolved ? (
							<>
								<div className="pageHeaderContentText">
									<p>Prueba gratis 7 días</p>
									<h5>9,99€ / Año</h5>
								</div>
								{loggedIn && (
									<CustomLinkExternal
										href={`https://buy.stripe.com/aEU8zAdnW9qJ0rC6ou?prefilled_email=${
											loggedIn ? usuario.email : ""
										}&client_reference_id=${
											loggedIn ? usuario.id : ""
										}&prefilled_promo_code=${coupon}`}
									>
										<p>Suscribirme</p>
									</CustomLinkExternal>
								)}
								{!loggedIn && (
									<CustomButton onClick={openLogin}>
										<p>Suscribirme</p>
									</CustomButton>
								)}
							</>
						) : (
							<TextButtonSkeleton />
						)}
					</div>
				</PageHeader>

				<PageContentWrapper bgColor={colors.grayF2}>
					<PageContent width="1050px">
						<PageTwoColWrapper>
							<div className="pageCol leftCol">
								<h4>
									Disfruta de una experiencia digital en la nieve.
								</h4>
								<p>
									Estos son nuestros planes para que puedas sacarle el
									máximo provecho a tu días en la montaña.
								</p>
							</div>
							<div className="pageCol fullCol">
								<ColumnsList
									items={pricesColumns}
									loggedIn={loggedIn}
								/>
							</div>
						</PageTwoColWrapper>
					</PageContent>
				</PageContentWrapper>

				<PageContentWrapper layout="dark">
					<PageContent width="1050px">
						<PageTwoColWrapper>
							<div className="pageCol leftCol">
								<h3>Beneficios de ser smart.</h3>
								<p>
									Aquí te contamos más en detalle como puede mejorar tu
									experiencia digital en la nieve.
								</p>
							</div>

							<div className="pageCol fullCol">
								<ModulesList items={subscriptionModulesItems} />
							</div>
						</PageTwoColWrapper>
					</PageContent>
				</PageContentWrapper>

				<PageContentWrapper bgColor={colors.grayF2}>
					<PageContent width="1050px">
						<PageTwoColWrapper>
							<div className="pageCol leftCol">
								<h3>FAQs</h3>
								<p>
									Aquí respondemos algunas preguntas que nos llegan
									frecuentemente, si lo que buscas no está aquí, por
									favor escríbenos y trataremos de responder tu
									consulta lo antes posible.
								</p>
							</div>

							<div className="pageCol fullCol">
								<AccordionCustom items={faqsData} />
							</div>

							<div className="pageCol leftCol">
								<p>
									Si aún tienes dudas, puedes contactarnos y en la
									brevedad responderemos tus consultas.
								</p>
								<br />
								<SliderButton id="kr8xczVw">
									<CustomButton className="ghost withUnderline">
										<p>Enviar Consulta</p>
									</CustomButton>
								</SliderButton>
							</div>
						</PageTwoColWrapper>
					</PageContent>
				</PageContentWrapper>
			</section>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		authResolved: state.auth.resolved,
		loggedIn: state.auth.loggedIn,
		usuario: state.auth.usuario ? state.auth.usuario : null,
		coupon: state.app.coupon,
	};
};

const mapDispatchToProps = {
	appQueuedRedirect,
	appModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuscripcionPage);
