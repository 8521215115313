import React, { useState } from "react";
import {
	ColumnsListWrapper,
	Column,
	ColContent,
	ColHeader,
	ColFooter,
	ColumnsWrapper,
	ColumnsTabNav,
} from "./styled";
import { ReactSVG } from "react-svg";
import {
	CustomButton,
	CustomLinkExternal,
} from "../../../assets/styles/globalCommon";
import { connect } from "react-redux";
import { SliderButton } from "@typeform/embed-react";
import {
	appModal,
	appQueuedRedirect,
	appQueuedRedirectReset,
} from "../../../redux/app/actions";
import ColumnListSkeleton from "./ColumnListSkeleton";

const ColumnsList = ({
	items,
	loggedIn,
	authResolved,
	usuario,
	coupon,
	isMobileResponsive,
	appModal,
	appQueuedRedirect,
	appQueuedRedirectReset,
}) => {
	const [activeTab, setActiveTab] = useState(2);

	const openLoginPremium = () => {
		appQueuedRedirectReset();
		appQueuedRedirect({
			to: "https://buy.stripe.com/4gwaHI97G8mF6Q028b?prefilled_email=<userEmail>&client_reference_id=<userId>&prefilled_promo_code=<coupon>",
			external: true,
			after: "login",
		});
		appModal("popuplogin", { campaign: "signup" });
	};

	const openLoginSmart = () => {
		appQueuedRedirectReset();
		appQueuedRedirect({
			to: "https://buy.stripe.com/aEU8zAdnW9qJ0rC6ou?prefilled_email=<userEmail>&client_reference_id=<userId>&prefilled_promo_code=<coupon>",
			external: true,
			after: "login",
		});
		appModal("popuplogin", { campaign: "signup" });
	};

	const openLogin = () => {
		appQueuedRedirectReset();
		appModal("popuplogin", { campaign: "signup" });
	};

	const renderSubscribeStarter = () => {
		if (loggedIn) {
			if (!usuario?.plan) {
				return (
					<CustomLinkExternal className="outline">
						<p>Plan Actual</p>
					</CustomLinkExternal>
				);
			} else if (usuario?.plan) {
				return (
					<SliderButton
						id="Rikn8Uxa"
						hidden={{
							nombre: usuario.nombre,
						}}
					>
						<CustomButton>
							<p>Cambiar a Starter</p>
						</CustomButton>
					</SliderButton>
				);
			}
		} else {
			return (
				<CustomButton onClick={openLogin}>
					<p>Registrarse</p>
				</CustomButton>
			);
		}
	};

	const renderSubscribePremium = () => {
		if (loggedIn) {
			if (usuario?.plan === "premium") {
				return (
					<CustomLinkExternal className="outline">
						<p>Plan Actual</p>
					</CustomLinkExternal>
				);
			} else if (usuario?.plan !== "premium") {
				return (
					<CustomLinkExternal
						href={`https://buy.stripe.com/4gwaHI97G8mF6Q028b?prefilled_email=${
							loggedIn ? usuario.email : ""
						}&client_reference_id=${
							loggedIn ? usuario.id : ""
						}&prefilled_promo_code=${coupon}`}
					>
						<p>Suscribirme</p>
					</CustomLinkExternal>
				);
			}
		} else {
			return (
				<CustomButton onClick={openLoginPremium}>
					<p>Suscribirme</p>
				</CustomButton>
			);
		}
	};

	const renderSubscribeSmart = () => {
		if (loggedIn) {
			if (usuario?.plan === "smart") {
				return (
					<CustomLinkExternal className="outline">
						<p>Plan Actual</p>
					</CustomLinkExternal>
				);
			} else if (usuario?.plan !== "smart") {
				return (
					<CustomLinkExternal
						href={`https://buy.stripe.com/aEU8zAdnW9qJ0rC6ou?prefilled_email=${
							loggedIn ? usuario.email : ""
						}&client_reference_id=${
							loggedIn ? usuario.id : ""
						}&prefilled_promo_code=${coupon}`}
					>
						<p>Suscribirme</p>
					</CustomLinkExternal>
				);
			}
		} else {
			return (
				<CustomButton onClick={openLoginSmart}>
					<p>Suscribirme</p>
				</CustomButton>
			);
		}
	};

	return (
		<ColumnsListWrapper>
			{isMobileResponsive && authResolved && (
				<ColumnsTabNav>
					{items?.map(({ title }, index) => (
						<button
							key={index}
							className={activeTab === index ? "active" : ""}
							onClick={() => setActiveTab(index)}
						>
							<span>{title}</span>
						</button>
					))}
				</ColumnsTabNav>
			)}
			<ColumnsWrapper>
				{authResolved && items
					? items.map((item, index) => {
							const {
								name,
								layout,
								title,
								priceType,
								price,
								priceText,
								features,
								footerText,
							} = item;
							return (
								<Column
									key={index}
									layout={layout}
									current={activeTab === index}
								>
									<ColHeader layout={layout}>
										<div className="headerTop">
											{priceType ? (
												<h6
													className="priceType"
													dangerouslySetInnerHTML={{
														__html: priceType,
													}}
												/>
											) : (
												<h6 className="priceType">&nbsp;</h6>
											)}
										</div>
										<div className="headerTitle">
											<h3>{title}</h3>
											<div className="priceWrapper">
												<h6
													className="price"
													dangerouslySetInnerHTML={{
														__html: price,
													}}
												/>
											</div>
										</div>
										<div className="headerText">
											{priceText ? (
												<p
													dangerouslySetInnerHTML={{
														__html: priceText,
													}}
												/>
											) : (
												<p>
													<span>&nbsp;</span>
												</p>
											)}
										</div>

										{name === "default" && renderSubscribeStarter()}
										{name === "premium" && renderSubscribePremium()}
										{name === "smart" && renderSubscribeSmart()}
									</ColHeader>

									<ColContent layout={layout}>
										<h5>Incluye</h5>

										<div className="listWrapper">
											{features?.map((feature, featIndex) => {
												return (
													<div
														className={`listItem ${feature.status}`}
														key={featIndex}
													>
														<p>{feature.text}</p>
														<div>
															{feature.icon ? (
																<ReactSVG
																	src={feature.icon}
																	wrapper="span"
																	className="icon"
																/>
															) : (
																<h6>{feature.qty}</h6>
															)}
														</div>
													</div>
												);
											})}
										</div>
									</ColContent>

									<ColFooter>
										{footerText && (
											<p
												dangerouslySetInnerHTML={{
													__html: footerText,
												}}
											/>
										)}
									</ColFooter>
								</Column>
							);
					  })
					: [...Array(3).keys()].map((_item, index) => (
							<ColumnListSkeleton
								key={index}
								current={activeTab === index}
							/>
					  ))}
			</ColumnsWrapper>
		</ColumnsListWrapper>
	);
};

const mapStateToProps = (state) => {
	const { isMobileResponsive } = state.app.global;
	const { usuario, resolved: authResolved } = state.auth;
	const { coupon } = state.app;
	return {
		authResolved,
		isMobileResponsive,
		usuario,
		coupon,
	};
};

const mapDispatchToProps = {
	appModal,
	appQueuedRedirect,
	appQueuedRedirectReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(ColumnsList);
