import React from "react";
import ContentLoader from "react-content-loader";
import { colors } from "../../../assets/styles/vars";
import styled from "styled-components";

const TextButtonSkeletonWrapper = styled.div`
	//background-color: ${colors.light20};
`;

const TextButtonSkeleton = (props) => (
	<TextButtonSkeletonWrapper>
		<ContentLoader
			speed={2}
			width="100%"
			height={51}
			// viewBox="0 0 388 51"
			backgroundColor={colors.light20}
			foregroundColor={colors.gray40}
			{...props}
		>
			<rect y="15" width="35.5%" height="20" />
			<rect x="37.5%" y="13" width="26.5%" height="24" />
			<rect x="66%" width="33%" height="51" />
		</ContentLoader>
	</TextButtonSkeletonWrapper>
);

export default TextButtonSkeleton;
