import React from "react";
import ContentLoader from "react-content-loader";
import { colors, mediaQueryMax } from "../../../assets/styles/vars";
import styled from "styled-components";

const ColumnListSkeletonWrapper = styled.div`
	flex: 1;
	max-width: 333px;
	//background-color: ${colors.light20};
	@media ${mediaQueryMax.lg} {
		flex: 0 0 100%;
		max-width: 100%;
		display: ${(props) => (props.current ? "block" : "none")};
	}
`;

const ColumnListSkeleton = (props) => (
	<ColumnListSkeletonWrapper current={props.current}>
		<ContentLoader
			speed={2}
			width="100%"
			height={846}
			// viewBox="0 0 333 846"
			backgroundColor={colors.light20}
			foregroundColor={colors.gray40}
			{...props}
		>
			{/*TOP*/}
			<rect x="7.5%" y="58" width="38%" height="32" />

			<rect x="59%" y="101" width="33%" height="18" />
			<rect x="59%" y="54" width="33%" height="38" />
			<rect x="59%" y="23" width="33%" height="18" />
			{/*TOP*/}

			{/*BUTTON*/}
			<rect x="7.5%" y="140" width="85%" height="51" />
			{/*BUTTON*/}

			{/*LIST*/}
			<rect x="7.5%" y="211" width="21%" height="23" />

			<rect x="7.5%" y="264" width="85%" height="18" />
			<rect x="7.5%" y="307" width="85%" height="18" />
			<rect x="7.5%" y="350" width="85%" height="18" />
			<rect x="7.5%" y="393" width="85%" height="18" />
			<rect x="7.5%" y="436" width="85%" height="18" />
			<rect x="7.5%" y="479" width="85%" height="18" />
			<rect x="7.5%" y="522" width="85%" height="18" />
			<rect x="7.5%" y="565" width="85%" height="18" />
			<rect x="7.5%" y="608" width="85%" height="18" />
			<rect x="7.5%" y="651" width="85%" height="18" />
			{/*LIST*/}

			{/*FOOTER*/}
			<rect x="7.5%" y="724" width="85%" height="90" />
			{/*FOOTER*/}
		</ContentLoader>
	</ColumnListSkeletonWrapper>
);

export default ColumnListSkeleton;
